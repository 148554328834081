import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'

import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'

const NotFoundPage = () => (
  <Layout>
    <GatsbySeo title="404: Not found" description="This page doesn't exist." />
    <GridContent layout="--center-4 fullscreen" mode="light-mode">
      <h1>This page doesn't exist.</h1>
      <p>
        You may have been sent a link that was mis-typed, or maybe trying to
        read an article that has been deleted. Choose a link from the menu
        above.
      </p>
    </GridContent>
  </Layout>
)

export default NotFoundPage
